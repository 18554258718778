export default {
  addNewBook: "Přidat novou knihu",
  addBook: "Přidat knihu",
  fieldCannotBeEmpty: "Pole nesmí být prázdné",
  name: "Název",
  author: "Autor",
  country: "Stát",
  addedOn: "Přidáno dne",
  yourBooks: "Moje knihy",
  search: "Hledání",
  lastAdded: "Naposledy přidáno",
  bookCount: "Knihy",
  addedOnItem: "přidáno",
  settings: "Nastavení",
  backgroundColor: "Barva pozadí",
  minColor: "Barva nejnižší hodnoty",
  maxColor: "Barva nejvyšší hodnoty",
  strokeColor: "Barva hranic",
  hoverColor: "Barva náhledu",
  uiTheme: "Styl rozhraní",
  resetToDefault: "Obnovit základní nastavení",
  cancel: "Zrušit",
  save: "Uložit",
  dark: "Tmavý",
  light: "Světlý",
  language: "Jazyk",
  englishUS: "Angličtina (US)",
  englishUK: "Angličtina (UK)",
  czech: "Čeština",
  welcome: "Vítejte na <strong>Alkuluku</strong>",
  email: "Email",
  password: "Heslo",
  signIn: "Přihlásit",
  newUser: "Nový uživatel?",
  createAccount: "Vytvořit účet",
  signUp: "Registrovat",
  existingUser: "Existující uživatel?",
  confirmPassword: "Potvrzení hesla",
  fieldMustBeEmail: "Hodnota musí být platná emailová adresa",
  passwordMinLength: "Heslo musí mít alespoň 8 znaků",
  passwordsDontMatch: "Hesla musí bý stejná",
  bookAdded: "Kniha úspěšně přidána",
  bookRemoved: "Kniha úspěšně odebrána",
  settingsUpdated: "Nastavení úspěšně uloženo",
  loggedIn: "Přihlášení proběhlo úspěšně",
  loginFailed: "Přihlášení selhalo",
  registerFailed: "Vytvoření účtu selhalo",
  removeBook: "Odebrat knihu",
  logout: "Odhlásit",
  showBooks: "Zobrazit přidané knihy",
  rememberMe: "Zůstat přihlášen",
  forgotPassword: "Zapomenuté heslo?",
  typeEmail: "Zadejte svůj email",
  requestPasswordReset: "Obnovit heslo",
  passwordResetRequested:
    "Žádsot o obnovení hesla byla úspěšná, zkontrolujte svoji emailovou schránku",
  passwordResetFailed: "Nedokázali jsme zpracovat vaši žádost o obnovení hesla",
  typeNewPassword: "Zadejte svoje nové heslo",
  resetPassword: "Resetovat heslo",
  passwordResetSuccess: "Heslo úspěšně resetováno",
  mapColorRules: "Barevná škála mapy",
  minValue: "Min. hodnota",
  minValueColor: "Barva min. hodnoty",
  maxValue: "Max. hodnota",
  maxValueColor: "Barva max. hodnoty",
  gradient: "Přechod",
  deleteRule: "Smazat škálu",
  addRule: "Přidat škálu",
  min: "min",
  max: "max",
  legend: "Legenda",
  screenshot: "Snímek obrazovky",
};
