export default {
  addNewBook: "Add new book",
  addBook: "Add book",
  fieldCannotBeEmpty: "Field cannot be empty",
  name: "Name",
  author: "Author",
  country: "Country",
  addedOn: "Added on",
  yourBooks: "Your books",
  search: "Search",
  lastAdded: "Last added",
  bookCount: "Books",
  addedOnItem: "added on",
  settings: "Settings",
  backgroundColor: "Background color",
  minColor: "Min scale color",
  maxColor: "Max scale color",
  strokeColor: "Border color",
  hoverColor: "Hover color",
  uiTheme: "UI Theme",
  resetToDefault: "Reset to default",
  cancel: "Cancel",
  save: "Save",
  dark: "Dark",
  light: "Light",
  language: "Language",
  englishUS: "English (US)",
  englishUK: "English (UK)",
  czech: "Czech",
  welcome: "Welcome to <strong>Alkuluku</strong>",
  email: "Email",
  password: "Password",
  signIn: "Sign in",
  newUser: "New here?",
  createAccount: "Create new account",
  signUp: "Sign up",
  existingUser: "Existing user?",
  confirmPassword: "Confirm password",
  fieldMustBeEmail: "Value must be a valid email address",
  passwordMinLength: "Password must be at least 8 characters long",
  passwordsDontMatch: "Passwords must match",
  bookAdded: "Book added successfully",
  bookRemoved: "Book removed successfully",
  settingsUpdated: "Settings updated successfully",
  loggedIn: "You are now logged in",
  loginFailed: "Login attempt failed",
  registerFailed: "Account creation failed",
  removeBook: "Remove book",
  logout: "Logout",
  showBooks: "Show added books",
  rememberMe: "Remember me",
  forgotPassword: "Forgot password?",
  typeEmail: "Type in your email to request a password reset",
  requestPasswordReset: "Request password reset",
  passwordResetRequested:
    "Password reset requested, check your email to continue",
  passwordResetFailed: "Could not process password reset",
  typeNewPassword: "Type in your new password",
  resetPassword: "Reset password",
  passwordResetSuccess: "Password reset successful",
  mapColorRules: "Map color rules",
  minValue: "Min value",
  minValueColor: "Min value color",
  maxValue: "Max value",
  maxValueColor: "Max value color",
  gradient: "Gradient",
  deleteRule: "Delete rule",
  addRule: "Add rule",
  min: "min",
  max: "max",
  legend: "Legend",
  screenshot: "Take a screenshot",
};
