import { defaultSettings } from "@/consts";

export default {
  user: {
    email: "",
  },
  settings: defaultSettings,
  countries: {},
  countryData: {},
};
